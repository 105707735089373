import React from "react"
import TemplatePageHome from "@components/pageHome/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div>
    <Helmet htmlAttributes={{ lang: "es" }}>
      <title>Beex: Software para Contact Center con IA [Antes Securitec]</title>
      <link rel="icon" type="image/png" href={`https://beexcc.com/Beex.png`} />
      <link rel="canonical" href={`https://beexcc.com/`} />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Automatiza tus campañas de inbound y outbound de tu empresa con utilizando nuestra plataforma omnicanal. Automatiza, integra y potencia tu Contact Center"
      />

      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Beex: Software para Contact Center con IA"
      />
      <meta property="og:url" content={`https://beexcc.com/`} />
      <meta property="og:image" content={`https://beexcc.com/open-Beex.png`} />
      <meta property="og:site_name" content={`Beex`} />
      <meta property="og:author" content={`Securitec Peru SAC`} />
      <meta
        property="og:description"
        content="Automatiza tus campañas de inbound y outbound de tu empresa con utilizando nuestra plataforma omnicanal. Automatiza, integra y potencia tu Contact Center"
      />
      <script type="application/ld+json">
        {`{
          "@context": "http://www.schema.org",
          "@type": "Corporation",
          "name": "Beex",
          "url": "https://beexcc.com/",
          "sameAs": [
            "https://beexcc.com/contact-center/",
            "https://beexcc.com/conversations/",
            "https://beexcc.com/blog/",
            "https://www.linkedin.com/company/beexcc/",
            "https://www.facebook.com/beexcc"
          ],
          "logo": "http://beexcc.com/Imagotipo-Beex.png",
          "description": "Beex es una empresa que ofrece tecnología omnicanal y software para Call Centers y Contact Centers. Mejora la comunicación digital de las empresas a través de diferentes canales, como el de voz, WhatsApp Business API, redes sociales, chat, SMS, chatbots y más.",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Jr. Pachacutec 1315, Of. 503, Jesus Maria",
            "addressLocality": "Lima",
            "addressRegion": "Lima",
            "addressCountry": "Peru"
          }
        }`}
      </script>
    </Helmet>
    <TemplatePageHome location={location} />
  </div>
)

export default IndexPage
