import React from "react"
import img from "./images/imgSupport.webp"
import iconOne from "./images/iconOne.svg"
import iconTwo from "./images/iconTwo.svg"
import iconThree from "./images/iconThree.svg"
import TitleUnderline from "./titleUnderline"

import { Link } from "gatsby"

const Support = ({ location }) => {
  return (
    <section className="container-support">
      <p className="container-support-title">
        Recibe el <TitleUnderline underline="soporte" /> que realmente mereces y
        que rara vez recibes
      </p>
      <section className="container-support-info">
        <section>
          <p className="container-support-info-title">
            Porque sabemos que tu soporte, ventas o cobros no pueden verse
            interrumpidos, te ofrecemos una atención dedicada y personalizada a
            tus necesidades.
          </p>
          <div className="container-support-info-text">
            <img src={iconOne} alt="" loading="lazy" />
            <p>
              Nuestro equipo está capacitado para contestarte y resolver tus
              dudas en el menor tiempo posible.
            </p>
          </div>
          <div className="container-support-info-text">
            <img src={iconTwo} alt="" loading="lazy" />
            <p>
              La comunicación es nuestra primera herramienta para tener una
              mejor conexión con tu negocio y tu equipo.
            </p>
          </div>
          <div className="container-support-info-text">
            <img src={iconThree} alt="" loading="lazy" />
            <p>
              Ofrecemos un SLA escalable las 24 horas del día que aseguran la
              continuidad de tu operación.
            </p>
          </div>
          <Link
            to={`/soporte-con-amor/${location.search}`}
            className="container-support-info-button"
          >
            <span> Conoce más de nuestro soporte </span>
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.27567 18.0005C1.17418 18.001 1.07356 17.9821 0.979602 17.9448C0.885641 17.9075 0.80018 17.8525 0.728119 17.783C0.655836 17.7133 0.598463 17.6304 0.559311 17.539C0.520158 17.4476 0.5 17.3496 0.5 17.2506C0.5 17.1516 0.520158 17.0536 0.559311 16.9622C0.598463 16.8708 0.655836 16.7879 0.728119 16.7182L7.02881 10.5917C7.46207 10.1699 7.70543 9.59817 7.70543 9.00202C7.70543 8.40587 7.46207 7.83409 7.02881 7.41229L0.728119 1.28584C0.5829 1.14463 0.501316 0.95312 0.501316 0.753428C0.501316 0.553736 0.5829 0.362223 0.728119 0.221019C0.873339 0.0798156 1.0703 0.000488281 1.27567 0.000488281C1.48104 0.000488281 1.678 0.0798156 1.82322 0.221019L8.12391 6.34747C8.48301 6.69575 8.76791 7.1095 8.9623 7.56501C9.1567 8.02052 9.25676 8.50885 9.25676 9.00202C9.25676 9.49519 9.1567 9.98351 8.9623 10.439C8.76791 10.8945 8.48301 11.3083 8.12391 11.6566L1.82322 17.783C1.75116 17.8525 1.6657 17.9075 1.57174 17.9448C1.47778 17.9821 1.37717 18.001 1.27567 18.0005Z"
                         />
            </svg>
          </Link>{" "}
        </section>
        <section>
          <img
            className="container-support-info-image"
            srcSet={img}
            alt="suport"
            loading="lazy"
          />
        </section>
      </section>
    </section>
  )
}
export default Support
