import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      width="376"
      height="20"
      viewBox="0 0 376 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.2034 14.6538C60.9659 13.0941 4.39325 15.3562 1.19474 15.098C-2.1646 14.5505 2.3026 3.77704 5.46538 2.08304C6.53751 1.5046 7.82406 1.35998 10.7724 1.59756C13.3098 1.80414 29.9992 1.45295 75.1894 0.564629C75.1894 0.564629 175.308 0.0791534 185.261 0.1308C190.265 0.151458 186.28 0.750556 192.194 0.564629C204.774 0.161786 232.149 1.1534 241.709 0.781543C249.375 0.481993 251.018 0.512988 258.309 1.07077C263.545 1.47361 306.59 2.54785 310.361 2.07271C311.504 1.92809 312.344 2.03139 312.326 2.2793C312.934 2.33094 340.541 4.1489 340.684 4.48977C340.845 4.73767 341.685 4.91327 342.525 4.8203C344.383 4.61372 362.716 5.83257 370.597 7.30966C373.849 7.91909 375.957 8.42523 375.993 8.61115C376.261 10.4188 368.828 15.9966 366.898 17.3497C362.627 20.3349 362.895 20.211 349.761 18.5273C331.517 16.1825 331.446 17.2051 323.834 16.699C322.047 16.5854 309.986 15.852 291.205 15.1393C252.662 13.6725 253.592 14.0857 239.69 14.4885C237.956 13.7345 231.077 15.3459 226.985 14.034C225.287 13.4866 212.994 13.2284 210.224 13.7035C208.348 14.0237 145.307 12.3194 138.338 13.8378C125.919 12.4846 77.1014 14.4059 69.2034 14.6538Z"
        fill="#FFBA00"
      />
    </svg>
  </span>
)

export default titleUnderline
